"use client";

import { GlobalError } from "@common/components/Error";
import { GridItemContainer } from "@common/styles";
import { captureExceptionsWithSentry } from "@common/utils/error";
import { useEffect } from "react";
export default function Error({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    console.error(error);
    captureExceptionsWithSentry(error);
  }, [error]);
  return <GridItemContainer $useGridPadding data-sentry-element="GridItemContainer" data-sentry-component="Error" data-sentry-source-file="error.tsx">
			<GlobalError title="Looks like there is a technical issue!" description="Our team is working on fixing it. Please try again shortly." ctaText="Reload the page" onClick={() => {
      window.location.reload();
    }} data-sentry-element="GlobalError" data-sentry-source-file="error.tsx" />
		</GridItemContainer>;
}