import {
	DIET_TOKEN_STORAGE_KEY,
	USER_LOCAL_STORAGE_KEYS,
	USER_OBJ_COOKIE_KEY,
} from "@common/constants/storage_keys"
import { removeCookie, removeFromLocalStorage, setCookie } from "../../browser"
import { getSSOCookieTokenDomain } from "../../env"

export const setDietTokenInCookies = (dietToken: string): void => {
	setCookie(DIET_TOKEN_STORAGE_KEY, dietToken, {
		expires: 20,
		domain: getSSOCookieTokenDomain(),
	})
}

export const deleteDietTokenInCookies = (): void => {
	removeCookie(DIET_TOKEN_STORAGE_KEY, {
		domain: getSSOCookieTokenDomain(),
	})
}

export const clearAuthenticationDetails = () => {
	removeCookie(USER_OBJ_COOKIE_KEY)
	deleteDietTokenInCookies()

	Object.keys(USER_LOCAL_STORAGE_KEYS).forEach(key => {
		const keyToBeCleared =
			USER_LOCAL_STORAGE_KEYS[key as keyof typeof USER_LOCAL_STORAGE_KEYS]
		if (typeof keyToBeCleared === "object") {
			Object.keys(keyToBeCleared).forEach(subKey => {
				if (Object.prototype.hasOwnProperty.call(keyToBeCleared, subKey)) {
					removeFromLocalStorage(
						keyToBeCleared[subKey as keyof typeof keyToBeCleared],
					)
				}
			})
		} else {
			removeFromLocalStorage(keyToBeCleared)
		}
	})
}
