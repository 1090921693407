import { SITE_URLS } from "@common/constants"
import { clearAuthenticationDetails } from "@common/utils/auth/sso"
import { faro } from "@grafana/faro-web-sdk"
import { captureException, captureMessage } from "@sentry/nextjs"

export class ApiError extends Error {
	status: number

	errorCode: string

	errorType: string

	notifyUser: boolean

	timestamp: number

	constructor(
		message: string,
		status: number,
		errorCode: string,
		errorType: string,
		notifyUser: boolean,
		timestamp: number,
	) {
		super(message)
		this.status = status
		this.errorCode = errorCode
		this.errorType = errorType
		this.notifyUser = notifyUser
		this.timestamp = timestamp
	}
}

export const captureExceptionsWithFaro = (err: any) => {
	faro.api.pushError(err)
}

export const captureExceptionsWithSentry = (err: any) => {
	captureException(err)
}

export const captureMessageWithSentry = (msg: string) => {
	captureMessage(msg)
}

export function handleApiError(error: ApiError, status: number): void {
	captureExceptionsWithFaro(error)

	const errorMsg = !error?.notifyUser ? "Something went wrong" : error.message

	if (status === 401) {
		clearAuthenticationDetails()

		// this has to be replaced with router.push after landing page is moved here
		window.location.href = `${window.location.origin.split("?")[0]}${SITE_URLS.LOGOUT}`
	}

	throw new ApiError(
		errorMsg,
		status,
		error.errorCode,
		error.errorType,
		error.notifyUser,
		error.timestamp,
	)
}
